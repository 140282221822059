import { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import { replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import UpdateProfileForm from "./UpdateProfileForm";

const UpdateProfile = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Update Profile</title>
        <meta name="description" content={siteTitle + " - Update Profile"} />
        <meta name="keywords" content="Update Profile"></meta>
      </Helmet>
      <SiteBreadcrumb title="Update Profile" parent="Home" />

      <section className="login-page section-big-py-space">
        <div className="custom-container">
          <Row className="row">
            <Col
              xl="8"
              lg="8"
              md="8"
              className="offset-xl-2 offset-lg-2 offset-md-2"
            >
              <div className="theme-card">
                <h3 className="text-center">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Your Information",
                        "lbl_uprofile_title"
                      )
                    : "Your Information"}
                </h3>
                <UpdateProfileForm />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default UpdateProfile;
