import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";
const shippingTimes = [
  {
    id: 1,
    area: "North America",
    time: "15 days",
  },
  {
    id: 2,
    area: "European Union",
    time: "15 days",
  },
  {
    id: 3,
    area: "Vietnam & South East Asia",
    time: "5 days",
  },
  {
    id: 4,
    area: "Rest of the World",
    time: "15 days",
  },
];

const shippingCosts = [
  {
    id: 1,
    area: "North America",
    cost: "15$",
  },
  {
    id: 2,
    area: "European Union",
    cost: "15$",
  },
  {
    id: 3,
    area: "Vietnam & South East Asia",
    cost: "15$",
  },
  {
    id: 4,
    area: "Rest of the World",
    cost: "10$",
  },
];

const ShippingInfo = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Shipping and Production Information</title>
        <meta
          name="description"
          content={siteTitle + " - Shipping and Production Information"}
        />
        <meta
          name="keywords"
          content="Shipping and Production Information"
        ></meta>
      </Helmet>

      <SiteBreadcrumb
        title="Shipping and Production Information"
        parent="Home"
      />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            <Col lg="12" className="mb-2">
              <h4 className="mb-3">Production Times</h4>
              <p className="mb-2">
                In stock itself refers to the availability of a product that can
                be purchased and ready to send on the website, no need
                production time.
              </p>
              <p className="mb-2">
                Pre-order products order made through V1HubShop is produced
                on-demand.{" "}
                <b>Our current production time is up to 15 working days.</b>
                Please note that this time is required to guarantee you the best
                quality and service. This <i> does not include </i> shipping
                times. You can check estimated shipping times for each
                destination below. Production time may vary for some products.
              </p>
              <p className="mb-2">
                Important: Delivery times may be extended during sales and other
                promotional periods (Black Friday, Winter Sale)
              </p>
            </Col>
            <Col lg="12" className="mb-2">
              <h4 className="mb-3">Shipping Times</h4>
              <div className="mb-2">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Shipping Destination</th>
                      <th>
                        Shipping Time (worldwide delivery times may vary){" "}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {shippingTimes.map((item, idx) => (
                      <tr>
                        <td>{item.area}</td>
                        <td>{item.time}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="mb-2">
                <i>
                  Timings can not be guaranteed by V1HubShop and are provided by
                  the post services used for shipping.
                </i>
              </p>
            </Col>
            <Col lg="12">
              <h4 className="mb-3">Shipping Costs</h4>
              <div className="mb-2">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Shipping Destination</th>
                      <th>Cost</th>
                    </tr>
                  </thead>

                  <tbody>
                    {shippingCosts.map((item, idx) => (
                      <tr>
                        <td>{item.area}</td>
                        <td>{item.cost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="mb-2">
                If there are any issues with your order, please don't hesitate
                to contact us.
              </p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ShippingInfo;
