import * as actionType from "../actionTypes";

const setUser = (userObj) => {
  return {
    type: actionType.SET_USER,
    payload: userObj,
  };
};
const setGuestUser = (userId) => {
  return {
    type: actionType.SET_GUEST_USER,
    payload: userId,
  };
};

const logOutUser = () => {
  return {
    type: actionType.LOG_OUT_USER,
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setUser,
  logOutUser,
  setGuestUser,
};
