import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";

const contents = [
  {
    title: "Pre-order",
    list: [
      "Choose your desired item and payment option (full payment).",

      "Fill in your shipping details and choose desired payment method.",

      "Wait for the item to release. May refer to ETA (Estimate to Arrive). The estimated quarter to release for the item will be written (Delay without further notice). Make sure you can accept it before you purchase.",

      "We will send a shipping invoice to collect remaining balance once your order is ready to be shipped.",

      "Pay the shipping invoice and you will receive your tracking information soon.",
    ],
  },
  {
    title: "Ready Stock",
    list: [
      "You can only choose full payment option.",

      "Fill in your shipping details and choose desired payment method.",

      "Your item will be ready to ship in 5-30days.",

      "We will send a shipping invoice to collect shipping fee.",

      "Pay the shipping invoice and you will receive your tracking information soon",
    ],
  },
];

const OrderProcess = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Ordering Process</title>
        <meta name="description" content={siteTitle + " - Ordering Process"} />
        <meta name="keywords" content="Ordering Process"></meta>
      </Helmet>

      <SiteBreadcrumb title="Ordering Process" parent="Home" />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                <h4 className="mb-2">{content.title}</h4>
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        style={{ display: "list-item" }}
                      >
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {item}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default OrderProcess;
