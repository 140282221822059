import { Media } from "reactstrap";
import supperSale from "./../../../resources/themeContent/images/supper-sale.jpg";
import supperSaleMobile from "./../../../resources/themeContent/images/supper-sale-mobile.jpg";
import useMobileSize from "helpers/utils/isMobile";

const DiscountBannerOmg = () => {
  const isMobile = useMobileSize(576);
  return (
    <section
      className="custom-container"
      style={{ paddingTop: "16px", paddingBottom: "16px" }}
    >
      <Media
        src={isMobile ? supperSaleMobile : supperSale}
        className="img-fluid"
        alt="app-banner"
        style={{
          borderRadius: isMobile ? "12px" : "16px",
          minHeight: "96px",
          objectFit: "cover",
        }}
      />
      {/* <Container>
       <Row>
          <Col lg="12">
            <div className="discount-banner-contain">
              <h2>Discount on every single item on our site.</h2>
              <h1>
                <span>OMG! Just Look at the</span> <span>great Deals!</span>
              </h1>
              <div className="rounded-contain rounded-inverse">
                <div className="rounded-subcontain">
                  How does it feel, when you see great discount deals for each
                  product?
                </div>
              </div>
            </div>
          </Col>
        </Row> 
      </Container>*/}
    </section>
  );
};

export default DiscountBannerOmg;
