import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";
const contents = [
  {
    content: [
      'Welcome to V1HubShop, an e-commerce website operated by Vision One Technology LLC ("we," "us," or "our"). By accessing or using our website, you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before using our website.',
    ],
  },
  {
    title: "1. Use of the Website",
    list: [
      "1.1. You agree to use the website for lawful purposes only and in a manner that does not infringe on the rights of, or restrict or inhibit the use and enjoyment of, the website by any third party.",
      "1.2. We reserve the right to modify or discontinue the website, or any portion of it, at any time without notice.",
      "1.3. You may use this website only if you have reached the age of majority where you live and you can form legally binding contracts under applicable law. You may not use this website if you live in a jurisdiction where access to or use of the website or any part of it may be illegal or prohibited. It is solely your responsibility to determine whether your use of this website is lawful, and you must comply with all applicable laws.",
    ],
  },
  {
    title: "2. User Name and Password",
    list: [
      "2.1. Your V1HubShop website account may be accessed only by use of your login name and password. You are solely responsible and liable for any use and misuse of your login name and password and for all activities that occur under your login name and password. For security reasons, you must keep your login name and password confidential and not disclose them to any person or permit any other person to use them.",
      "2.2. All login names and passwords remain our property.  We are not under any obligation to verify the actual identity or authority of the user of any login name or password.",
      "2.3. You must immediately notify us of any unauthorized use of your login name or password, or if you know or suspect that your login name or password has been lost or stolen, has become known to any other person, or has been otherwise compromised.",
    ],
  },
  {
    title: "3. Product Information and Orders",
    list: [
      "3.1. We strive to provide accurate and up-to-date product information on the website. However, we do not warrant the accuracy, completeness, or reliability of any product information, descriptions, or images.",
      "3.2. Placing an order through our website constitutes an offer to purchase the products. We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing, or suspicion of fraudulent activity.",
      "3.3. All items presented on the web-site unless indicated otherwise are made-to-order on demand. Cancellations with refunds are only possible if the products have not been sent into production. The window for cancellations may be as short as 12 hours from the time the order is placed. This will vary based on the manufacturing capacity at the time of the order. We keep the right to decline refund for the cancellation if this condition is not complied with. ",
    ],
  },
  {
    title: "4. Shipping and Production",
    list: [
      "4.1. Our shipping and production information can be found on the Shipping & Production Information page. We aim to keep information on the page accurate however, we may take no responsibility for any delays in updating this information, or changing any information on the page without advanced warning. ",
      "4.2. If the parcel is returned to the sender, we will re-dispatch it upon its arrival at our facilities, unless it was refused by the receiver. The time it takes for the return to arrive will depend on the postal services in the country of arrival as well as Royal Mail timings.",
    ],
  },
  {
    title: "5. Post-purchase care and support",
    list: [
      "5.1. If a customer complains that the product is unsatisfactory or damaged and provides the required proof, and the complaint is justified and filed within 28 days of receiving the parcel, we will arrange the replacement of the models. The replacement will be shipped to the customer free of charge.",
      "5.2. We do not accept returns, as all products are made-to-order on demand unless indicated otherwise",
    ],
  },
  {
    title: "6. Intellectual Property",
    list: [
      "6.1. All content on the website, including text, graphics, logos, images, audio, and software, is the property of Vision One Technology LLC or its content suppliers and is protected by applicable copyright, trademark, and intellectual property laws.",
      "6.2. You may not use, reproduce, distribute, modify, or create derivative works based on any of the content without our prior written consent.",
    ],
  },
  {
    title: "7. Feedback",
    list: [
      "7.1. The Company enables visitors to V1HubShop to provide the Company with feedback by filling out the Contact Us form, leaving the review or otherwise (“Customer Content”).",
      "7.2. If you provide Customer Content you grant the Company a non-exclusive, royalty-free, perpetual, irrevocable, and fully sub licensable right to use, delete, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display that Customer Content",
    ],
  },
  {
    title: "8. Compliance with the General Product Safety Regulation (GPSR):",
    list: [
      "8.1. We confirm that all products offered for sale by our company are fully compliant with the requirements set forth under the General Product Safety Regulation (GPSR) (EU Regulation [2023/988]).",
      "8.2. In accordance with this regulation, we have implemented all necessary measures to ensure that our products meet the required safety standards, including conducting relevant safety assessments, maintaining the required technical documentation, and adhering to reporting obligations.",
      "8.3. We are duly authorized to sell our products within the European Union under the provisions of the GPSR. By purchasing our products, you acknowledge that our products meet these legal requirements and are safe for use in the EU market.",
      "8.4. For further details, you may refer to the official document Regulation (EU) 2023/988 - General Product Safety Regulation.",
    ],
  },
  {
    title: "9. Limitation of Liability",
    list: [
      "9.1. To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages, whether arising in contract, tort, or otherwise, including but not limited to damages for loss of profits, use, data, or other intangible losses, resulting from your use or inability to use the website.",
    ],
  },
  {
    title: "10. Indemnification",
    list: [
      "10.1. You agree to indemnify, defend, and hold harmless Vision One Technology LLC, its affiliates, officers, directors, employees, agents, and licensors from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in connection with your use of the website or violation of these Terms.",
    ],
  },
  {
    title: "11. Governing Law",
    list: [
      "11.1. These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law principles",
    ],
  },
  {
    title: "12. Privacy",
    list: [
      "12.1. You have read the Company’s Privacy Policy, which is incorporated into these Terms and Conditions, and you agree that the terms of that policy are reasonable. You consent to the collection, use and disclosure of your personal information by the Company in accordance with the terms of and for the purposes set out in the Company’s Privacy Policy.",
    ],
  },
  {
    title: "13. Changes to the Terms",
    list: [
      "13.1. We reserve the right to update or modify these Terms at any time without prior notice. By continuing to use the website after such changes, you agree to be bound by the updated Terms. Last updated 2024 Dec 29th",
    ],
  },
  {
    title: "14. Contact Us",
    list: [
      "14.1. If you have any questions about these Terms, please contact us here",
    ],
  },
  {
    content: [
      "By using our website, you acknowledge that you have read, understood, and agree to these Terms and Conditions.",
    ],
  },
];

const TermCondition = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Terms and Conditions</title>
        <meta
          name="description"
          content={siteTitle + " - Terms and Conditions"}
        />
        <meta name="keywords" content="Terms and Conditions"></meta>
      </Helmet>

      <SiteBreadcrumb title="Terms and Conditions" parent="Home" />

      <section className="about-page mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                {content.title && <h4 className="mb-2">{content.title}</h4>}
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2 pb-1"
                        // style={{ display: "list-item" }}
                      >
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {item}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default TermCondition;
