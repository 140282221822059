import { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  showErrorMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const [currentPassword, setCurrentPassword] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");

  const HandleLogout = () => {
    localStorage.setItem("user", JSON.stringify("{}"));
    dispatch(rootAction.userAction.setUser("{}"));
  };

  const validateOTPAndChangePassword = async (event) => {
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    try {
      event.preventDefault();

      let isValid = false;
      let validationArray = [];

      isValid = validateAnyFormField(
        "Password",
        Password,
        "password",
        6,
        20,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField(
        "ConfirmPassword",
        ConfirmPassword,
        "password",
        6,
        20,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      if (Password !== ConfirmPassword) {
        showErrorMsg("Password does not match!");
        validationArray.push({
          isValid: false,
        });
      }

      //--check if any field is not valid
      if (validationArray !== null && validationArray.length > 0) {
        isValid = false;
        return false;
      } else {
        isValid = true;
      }

      if (isValid) {
        let headersChangePassword = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        let paramChangePassword = {
          email: loginUser.EmailAddress,
          oldPassword: currentPassword,
          newPassword: Password,
          confirmPassword: ConfirmPassword,
        };

        //--make api call for data operation
        let responseChangePassword = await MakeApiCallAsync(
          Config.END_POINT_NAMES["CHANGE_PASSWORD"],
          Config["SUB_URL"],
          paramChangePassword,
          headersChangePassword,
          "PUT",
          true
        );

        if (
          responseChangePassword !== null &&
          responseChangePassword.data.isSuccess
        ) {
          showSuccessMsg(
            // responseChangePassword.data.message
            "Password changed successfully. Please login with your new password!"
          );
          HandleLogout();
          navigate("/" + getLanguageCodeFromSession() + "/login");
        } else {
          showErrorMsg(responseChangePassword.data.errorMessage);
          return false;
        }
      }
    } catch (err) {
      console.log(err);
      showErrorMsg("An error occurred. Please try again!");

      return false;
    } finally {
      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Change Password</title>
        <meta name="description" content={siteTitle + " - Change Password"} />
        <meta name="keywords" content="Change Password, Change Password"></meta>
      </Helmet>

      <SiteBreadcrumb title="Change Password" parent="home" />
      <section className="login-page">
        <div className="custom-container">
          <Row className="row align-items-center justify-content-center m-0">
            <Col xl="4" lg="6" md="8">
              <div className="theme-card">
                <Form
                  className="theme-form"
                  onSubmit={validateOTPAndChangePassword}
                >
                  <div className="form-row row justify-content-center">
                    <Col className="p-0" md="12">
                      <FormGroup>
                        <label htmlFor="currentPassword" className="form-label">
                          Current Password
                        </label>
                        <Input
                          type="password"
                          className="form-control"
                          id="currentPassword"
                          name="currentPassword"
                          placeholder="Enter Current Password"
                          required={true}
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label htmlFor="Password" className="form-label">
                          Password
                        </label>
                        <Input
                          type="password"
                          className="form-control"
                          id="Password"
                          name="Password"
                          placeholder="Enter new password"
                          required={true}
                          value={Password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label htmlFor="ConfirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <Input
                          type="password"
                          className="form-control"
                          id="ConfirmPassword"
                          name="ConfirmPassword"
                          placeholder="Confirm password"
                          required={true}
                          value={ConfirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <div>
                      <button className="btn btn-normal">
                        Change Password
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
