import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";

const contents = [
  {
    title:
      "Mass Manufacturing for your miniatures. Buy custom products in bulk and get a discount",
    content: [
      "Design and order products for your team, event, or organization and save with a bulk discount.",

      "Laser focused manufacturing with a high attention to detail. Rest assured we quality control every piece a number of times.",
    ],
  },
  {
    title: "Crowdfunding",
    content: [
      "Are you thinking of using a funding platform for creative projects like Kickstarter or Indiegogo, for your next product? We have works with a large amount from small 100 unit orders to 100,000+ unit orders.",
    ],
  },
  {
    title: "Fulfillment",
    content: [
      "We provide scalable fulfillment solutions designed to help every business, from startups to established concerns, drive new revenues, protect their brand, and deliver a superior customer experience.",

      "Track production, inventory and orders with data from V1HubShop. Integrate with our user-friendly technology and gain real-time visibility.",
    ],
  },
  {
    title: "Whitelabel Shipping Solutions,",
    content: [
      "White label integration is the ideal choice for established businesses that sell customizable products or provide services on demand. You can focus on building your brand while we produce behind the scenes.",
    ],
  },
  {
    title: "Benefits",
    list: [
      "Process automation",

      "Shipment to end customers",

      "Product branding",

      "Partner discount",

      "No start-up cost",
    ],
  },
];

const BulkProduction = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Bulk Production</title>
        <meta name="description" content={siteTitle + " - Bulk Production"} />
        <meta name="keywords" content="Bulk Production"></meta>
      </Helmet>

      <SiteBreadcrumb title="Bulk Production" parent="Home" />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                <h4 className="mb-2">{content.title}</h4>
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        style={{ display: "list-item" }}
                      >
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {item}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default BulkProduction;
